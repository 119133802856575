import React, { useEffect, useRef, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormElementWrapper } from "../form-element-wrapper/form-element-wrapper";
import { AutoComplete } from "./auto-complete";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Auto Complete",
	notepad: "",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Auto Complete"
			subTitle="Enable users to input a single line of text and suggests options as they type."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={AutoComplete} />

				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>AutoComplete</Code> component is a single-line text input field that dynamically
					suggests options from a pre-defined list based on user input. It is designed to improve
					the user experience by reducing the number of keystrokes required to complete a task and
					increasing the accuracy of input.
				</Paragraph>
				<Header.H3>Variant overview:</Header.H3>
				<DocsTable>
					<thead>
						<tr>
							<th>Variant</th>
							<th>Purpose</th>
							<th>Usage guideline</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Default</td>
							<td>Selecting from a known, predefined list of options.</td>
							<td>Suggestions update in real-time as the user types.</td>
						</tr>
						<tr>
							<td>Async</td>
							<td>Filtering through large or dynamic datasets sourced from a database or API.</td>
							<td>Fetches suggestions on-the-fly; includes loading indicator during retrieval.</td>
						</tr>
						<tr>
							<td>Invalid</td>
							<td>
								Informing the user of incorrect input that doesn't match available options or
								violates validation rules.
							</td>
							<td>
								Inherits styles from the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
								component; displays clear error messages.
							</td>
						</tr>
						<tr>
							<td>Disabled</td>
							<td>
								When the Autocomplete is temporarily unavailable or irrelevant in the current
								context.
							</td>
							<td>
								Prevents user interaction; can include a hint text or a visual clue explaining the
								disabled state.
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The default variant is the primary mode for user interaction. It functions by dynamically
					updating the list of suggestions in real time as the user types, making it ideal for
					scenarios where users need to select from a predefined set of options. To ensure a clear
					and uncluttered user experience, it's recommended to limit the number of suggestions
					displayed simultaneously.
				</Paragraph>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Input field</b>: The primary area where users type their query.
						</>,
						<>
							<b>Suggestion list</b>: A dynamic list of options appears below the input field,
							updating as the user types, with matched text highlighted.
						</>,
						<>
							<b>Loading indicator</b>: A visual cue (e.g., spinner) indicating suggestions are
							being fetched from an external source.
						</>,
						<>
							<b>Error message</b>: Clear feedback displayed when the input is invalid or doesn't
							match available options.
						</>,
					]}
				/>

				<Example fn={DefaultExample} />
				<Header.H3>Async</Header.H3>
				<Paragraph>
					The Async variant is designed to handle situations where the list of suggestions is
					extensive or needs to be dynamically updated. It retrieves options from an database or
					API, or hard-coded into the component, eliminating the need to load all possibilities
					upfront. During the retrieval process, a <Code>Spinner</Code> is displayed to provide
					visual feedback to the user.
				</Paragraph>
				<Example fn={AsyncExample} />
				<Header.H3>Invalid</Header.H3>

				<Paragraph>
					The Invalid variant, inheriting all states and visual properties, like{" "}
					<Code>disabled</Code>, <Code>invalid</Code>, and also visual properties like{" "}
					<Code>fullWidth</Code>, custom <Code>width</Code>, <Code>large</Code> size,{" "}
					<Code>leftSlug</Code> and <Code>rightSlug</Code>, etc. from the{" "}
					<GatsbyLink to="/lab/components/Forms and input/Input Field">Input Field</GatsbyLink>{" "}
					component, is designed to indicate erroneous input.
				</Paragraph>
				<Paragraph>
					This can include situations where the entered value doesn't match any suggestions,
					violates validation rules, or is simply left empty. Clear error messages are essential in
					these scenarios, providing users with information about the issue and guidance on
					resolving it.
				</Paragraph>
				<Paragraph>
					Ideally, data validation should occur before form submission to minimize user frustration.
					While error messages can highlight empty fields, ensuring proper labeling of required
					fields beforehand enhances user understanding and reduces the likelihood of error (see
					Best Practices in{" "}
					<GatsbyLink to="/lab/components/Forms and input/Input Field">Input Field</GatsbyLink>).
					Follow the writing guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#issues-and-issue-descriptions">
						Issues and issue descriptions
					</a>
					.
				</Paragraph>

				<Example fn={InvalidExample} />
				<Header.H3>Disabled</Header.H3>
				<Paragraph>
					The Disabled variant serves to visually indicate that the component is either temporarily
					unavailable or not applicable in the current situation. By maintaining the{" "}
					<Code>AutoComplete</Code>'s presence in the layout but preventing user interaction, it
					ensures a consistent visual experience.
				</Paragraph>
				<Paragraph>
					Whenever possible, a hint text or a visual clue (e.g{" "}
					<GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip</GatsbyLink>) should be included
					to clarify the reason for the component's disabled state, enhancing the overall user
					understanding.
				</Paragraph>
				<Example fn={DisabledExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={AutoComplete}
					initialProps={({ setState }) => ({
						"aria-label": "Label",
						placeholder: "Placeholder",
						value: "",
						onChange: (v) => setState({ value: v }),
						suggestions: ["Item 1", "Item 2", "Item 3"],
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Autocomplete</Code> when
					</Paragraph>
					<Ul
						items={[
							"Filtering through large datasets.",
							"Minimizing user typing effort.",
							"Guiding users towards valid options.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Autocomplete</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Filters">Filters</GatsbyLink>:
								Autocomplete enables efficient filtering of large lists.
							</>,
							<>
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Search Input Field
								</GatsbyLink>{" "}
								: Provide instant search suggestions to speed up navigation.
							</>,
							<>
								<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink> : Prefill
								common user information (e.g., addresses, names) to reduce input errors.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Autocomplete</Code> to existing components for visual
								consistency.
							</>,
							<>Clearly differentiate the selected suggestion from other options in the list.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The list of options (< 3) is small and easily scannable.",
							"The input requires free-form text without restrictions.",
							"The context doesn't require quick selection from a predefined list.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between the input field, suggestion list, and any error messages.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep suggestion labels concise and relevant to the user's input.",
							<>
								Use clear, actionable language for error messages. Follow{" "}
								<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#issues-and-issue-descriptions">
									Issues and issue descriptions
								</a>
								.
							</>,
							"Provide helpful instructions if the user encounters an empty suggestion list.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [value, setValue] = useState("");
	return (
		<AutoComplete
			suggestions={[
				"Apple",
				"Banana",
				"Blueberry",
				"Cherry",
				"Grape",
				"Guava",
				"Lemon",
				"Lime",
				"Orange",
				"Peach",
				"Pear",
				"Pineapple",
				"Raspberry",
				"Strawberry",
				"Watermelon",
			]}
			value={value}
			onChange={setValue}
			name="fruit"
			placeholder="Type a fruit"
			aria-label="List of fruits"
		/>
	);
};

const AsyncExample = () => {
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [value, setValue] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const debouncedSearch = useDebounceFn(onSearch, 500);

	async function onSearch(searchQuery: string, caseSensitive: boolean) {
		const data = await api.searchFruits(searchQuery, caseSensitive);
		setSuggestions(data.map((fruit) => fruit.name));
		setLoading(false);
	}

	return (
		<AutoComplete
			loading={loading}
			suggestions={suggestions}
			value={value}
			onChange={setValue}
			onSearch={(q) => {
				setLoading(true);
				debouncedSearch(q, false);
			}}
			name="fruit"
			placeholder="Type a fruit"
			aria-label="List of fruits"
		/>
	);
};

const InvalidExample = () => {
	const [value, setValue] = useState("");
	return (
		<FormElementWrapper
			label="Fruit"
			name="Fruit"
			invalid
			error="Some error message to let the user know what's wrong"
		>
			<AutoComplete
				suggestions={["Apple", "Banana", "Blueberry", "Cherry"]}
				value={value}
				onChange={setValue}
				placeholder="Type a fruit"
			/>
		</FormElementWrapper>
	);
};

const DisabledExample = () => {
	const [value, setValue] = useState("");
	return (
		<AutoComplete
			disabled
			suggestions={["Item 1", "Item 2", "Item 3"]}
			value={value}
			onChange={setValue}
			name="some input"
			placeholder="Placeholder"
			aria-label="List of suggestions"
		/>
	);
};

function sleep(n: number) {
	return new Promise((resolve) => setTimeout(resolve, n));
}

function useDebounceFn(callback: (...args: any[]) => void, time = 500): (...args: any[]) => void {
	const timeout = useRef<NodeJS.Timeout>();

	useEffect(() => () => timeout.current && clearTimeout(timeout.current), [timeout]);

	return (...args: any[]) => {
		timeout.current && clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			callback(...args);
		}, time);
	};
}

const api = {
	fruits: [
		{ id: 0, name: "Apple" },
		{ id: 1, name: "Banana" },
		{ id: 2, name: "Blueberry" },
		{ id: 3, name: "Cherry" },
		{ id: 4, name: "Grape" },
		{ id: 5, name: "Guava" },
		{ id: 6, name: "Lemon" },
		{ id: 7, name: "Lime" },
		{ id: 8, name: "Orange" },
		{ id: 9, name: "Peach" },
		{ id: 10, name: "Pear" },
		{ id: 11, name: "Pineapple" },
		{ id: 12, name: "Raspberry" },
		{ id: 13, name: "Strawberry" },
		{ id: 14, name: "Watermelon" },
	],
	async searchFruits(query: string, caseSensitive: boolean): Promise<typeof this.fruits> {
		if (query.length === 0) {
			return this.fruits.slice(0, 5);
		} else {
			await sleep(1000);
			const parsedQuery = caseSensitive ? query : query.toLowerCase();
			return this.fruits.filter((fruit) => {
				const value = caseSensitive ? fruit.name : fruit.name.toLowerCase();
				return value.includes(parsedQuery);
			});
		}
	},
};
